export const aimmoConstants = 'aimmoConstants';

// aimmo core
export const AIMMO_CORE_DEV_URL = 'https://dev-core.aimmo.ai';
export const AIMMO_CORE_QA_URL = 'https://qa-core.aimmo.ai';
export const AIMMO_CORE_PROD_URL = 'https://core.aimmo.ai';
export const AIMMO_CORE_DOCS_URL_KO = 'https://m.site.naver.com/1s6e8';
export const AIMMO_CORE_DOCS_URL_EN = 'https://m.site.naver.com/1s6gR';
export const AIMMO_CORE_INSTALL_URL = 'https://docs.aimmo.ai/v1.0.0-Core-en/docs/installation';
export const AIMMO_CORE_SMART_CURATION_DOCS_URL = 'https://docs.aimmo.ai/v1.0.0-Core-en/docs/tutorial';
export const AIMMO_CORE_CONTACT_KO = 'https://contactkr.aimmo.ai?v=2';
export const AIMMO_CORE_CONTACT_EN = 'https://contact.aimmo.ai?v=2';
export const AIMMO_CORE_CONTACT_FOR_AZURE = 'https://form.typeform.com/to/UJsLLeOE';
export const AIMMO_CORE_SOFTWARE_TERMS = 'https://www.aimmo.ai/privacy-and-terms/software-and-services-agreement';
export const AIMMO_CORE_SERVICE_TERMS = 'https://www.aimmo.ai/privacy-and-terms/terms-of-use-for-service-user';
export const AIMMO_CORE_PRIVACY_TERMS = 'https://www.aimmo.ai/privacy-and-terms/privacy-policy';
